<template>
  <div class="users-management-body body">
    <div class="content-search">
      <ui-input @input="searchList"  search :placeholder="$t('search')" v-model="search" />
      <div class="content-search__icons">
        <img @click="visibleFilter = !visibleFilter" :src="require(`@/assets/svg/search/${filterColor}-filter.svg`)" alt="">
        <ui-menu v-model="visibleSort">
          <template #activator>
            <img :src="require(`@/assets/svg/search/${sortColor}-sort.svg`)" alt="">
          </template>
          <template #body>
            <div class="sort">
              <p
                  @click="changeSort(item)"
                  v-for="(item, key) in sortData"
                  :class="['sort__item', { active: item.value === sortBy.value }]"
                  :key="key"
              >
                {{ item.text }}
              </p>
            </div>
          </template>
        </ui-menu>
      </div>
    </div>
    <search-filter
        v-if="visibleFilter"
        @submit="updateList"
        @reset="resetSearch"
        :disabled="isPointsValid || isNumbersValid"
    >
      <!--      <ui-select-->
      <!--          label="Регион/Город"-->
      <!--          :options="getRegions"-->
      <!--      />-->
      <ui-input
          v-model="searchObj.scoreFrom"
          :label="$t('number-of-points-from')"
          :error="isPointsValid"
          v-if="getUser.role !== 'Moderator'"
          v-mask="'########'"
      />
      <ui-input
          v-model="searchObj.scoreTo"
          :label="$t('number-of-points-to')"
          :error="isPointsValid"
          v-if="getUser.role !== 'Moderator'"
          v-mask="'#########'"
      />
      <ui-input
          v-model="searchObj.ordersFrom"
          :label="$t('number-of-requests-from')"
          :error="isNumbersValid"
          v-if="getUser.role !== 'Moderator'"
          v-mask="'#########'"
      />
      <ui-input
          v-model="searchObj.ordersTo"
          :label="$t('number-of-requests-to')"
          :error="isNumbersValid"
          v-if="getUser.role !== 'Moderator'"
          v-mask="'#########'"
      />
      <ui-select
          v-model="searchObj.region"
          :label="$t('location-residence')"
          :options="getRegions"
      />
    </search-filter>
    <ui-loader v-model="visibleLoader" />
    <h2 v-if="!visibleLoader" class="body__title">{{ $t('length-users', {from: membersData.length, to: total}) }}</h2>
    <div v-if="!visibleLoader" class="body__cards">
      <template v-if="membersData.length">
        <users-management-card v-for="(item, key) in membersData" :key="key" :data="item" />
      </template>
      <div v-else class="empty">
        {{ $t('empty') }}
      </div>
    </div>
    <ui-button v-if="visibleLoadMore" @click="loadMore" color="white">
      {{ $t('show-more') }}
    </ui-button>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { capitalize, getDate } from "@/utils/general";
import { getUserRole } from "@/utils/user";
import UsersManagementCard from "@/components/template/usersManagement/UsersManagementCard";
import UiLoader from "@/components/ui/UiLoader";

export default {
  name: "UsersManagementMain",
  components: {
    UiLoader,
    UsersManagementCard,
    SearchFilter: () => import('@/components/common/SearchFilter'),
    UiButton: () => import('@/components/ui/UiButton'),
    UiInput: () => import('@/components/ui/UiInput'),
    UiMenu: () => import('@/components/ui/UiMenu'),
    UiSelect: () => import('@/components/ui/UiSelect'),
  },

  data() {
    return {
      visibleLoader: true,
      visibleLoadMore: false,
      getDate,
      capitalize,
      getUserRole,
      total: 0,
      search: '',
      visibleFilter: false,
      visibleSort: false,
      sortBy: '',
      sortData: [
        { text: this.$t('date'), value: 'date' },
        { text: this.$t('number'), value: 'number' },
        // { text: 'Размеру урона', value: 'damage' },
        { text: this.$t('type'), value: 'type' }
      ],
      searchObj: [],
    }
  },

  watch: {
    $route() {
      this.updateList()
    }
  },

  computed: {
    ...mapGetters(['membersData', 'getUser', 'getRegions']),

    filterColor() {
      return this.visibleFilter ? 'red' : 'black'
    },

    sortColor() {
      return this.visibleSort ? 'red' : 'black'
    },

    isPointsValid() {
      return (Number(this.searchObj.scoreFrom) >= Number(this.searchObj.scoreTo)) && !!this.searchObj.scoreFrom && !!this.searchObj.scoreTo
    },

    isNumbersValid() {
      return (Number(this.searchObj.ordersFrom) >= Number(this.searchObj.ordersTo)) && !!this.searchObj.ordersFrom && !!this.searchObj.ordersTo
    }
  },

  methods: {
    ...mapActions(['users']),
    ...mapMutations(['setMembersData']),

    resetSearch() {
      this.searchObj = {}
      this.updateList(true)
    },

    searchList() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.updateList()
      }, 500)
    },

    changeSort(val) {
      this.sortBy = val
      this.visibleSort = false
      this.updateList()
    },

    updateList(reset = false) {
      this.visibleLoader = true
      this.visibleFilter = reset
      this.users({
        sort: this.sortBy?.value,
        roles: ['Moderator', 'Admin'],
        take: 21,
        search: this.search,
        scoreFrom: this.searchObj.scoreFrom,
        scoreTo: this.searchObj.scoreTo,
        ordersFrom: this.searchObj.ordersFrom,
        ordersTo: this.searchObj.ordersTo,
        regionId: this.searchObj.region?.id,
      }).then((res) => {
        this.total = res.meta.total
        this.visibleLoader = false
        this.setMembersData(res.data)
        if (res.data.length === 21) {
          this.visibleLoadMore = true
          return
        }

        this.visibleLoadMore = false
      })
    },

    getMembersList() {
      this.visibleLoader = true
      this.users({
        sort: this.sortBy?.value,
        roles: ['Moderator', 'Admin'],
        take: 21,
        skip: this.membersData.length,
        search: this.search,
      }).then((res) => {
        this.total = res.meta.total
        this.visibleLoader = false
        this.setMembersData(this.$store.state.usersStore.membersData.concat(res?.data))
        if (res.data.length === 21) {
          this.visibleLoadMore = true
          return
        }

        this.visibleLoadMore = false
      })
    },

    loadMore() {
      this.users({
        sort: this.sortBy?.value,
        roles: ['Moderator', 'Admin'],
        take: 21,
        skip: this.membersData.length,
        search: this.search,
      }).then((res) => {
        this.total = res.meta.total
        this.visibleLoader = false
        this.setMembersData(this.$store.state.usersStore.membersData.concat(res?.data))
        if (res.data.length === 21) {
          this.visibleLoadMore = true
          return
        }
        this.visibleLoadMore = false
      })
    }
  },
  mounted() {
    this.getMembersList()
  },
  destroyed() {
    this.$store.state.usersStore.membersData = []
  },
  created() {
    this.setMembersData([])
  }
}
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  margin-left: 38px;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #9A9A9A;
    margin-bottom: 20px;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 50px;
  }
}
.content-search {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #fff;

  @media (max-width: 992px) {
    gap: 20px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    padding: 0;
    border: none;
  }

  &__icons {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 992px) {
      gap: 10px;
    }

    img {
      cursor: pointer;
    }
  }
}

::v-deep .filter__row {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
}

</style>
